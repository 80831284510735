.button {
    font-family: var(--main-font);
    font-weight: var(--font-weight-l);
    padding: calc(var(--space-m) * 0.75) var(--space-xl);
    height: auto;
    line-height: 1.5;
    font-size: var(--font-size-s);
    --icon-size: 16px;
}

.button img {
    width: var(--icon-size);
    height: var(--icon-size);
}

.button_solid {
    color: var( --on-surface-color)
}

.button_filled {
    --min-width: 150px;
    min-width: var(--min-width);
}

.button_faded {
    background-color: var(--on-surface-color);
    color: var(--secondary-text-color);
}

.button_primary_faded {
    background-color: var(--on-surface-color);
    color: var(--primary-color);
    border-color:var(--primary-color);
}

.button_link {
    height: auto;
    font-weight: var(--font-weight-m);
    padding: 0 var(--space-xs);
}
