.typography {
    font-family: var(--main-font);
    color: var(--text-color);
}

.p {
    font-size: var(--font-size-m);
}

.h1 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-l);
}

.h2 {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-l);
}

.h3 {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-l);
}
