@keyframes showUp {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showFade {
    from { opacity: 0 }
    to { opacity: 1 }
}

.trainingTask {
    display: grid ;
    grid-template-columns: 300px 1fr 300px;
    grid-template-rows: 70px calc(100vh - 140px - var(--page-padding-m) * 2 - var(--space-m) * 2) 70px;
    grid-template-areas:
            "header header header"
            "git task habr"
            "sber buttons e";
    align-items: center ;
    justify-content: center;
    justify-items: center;
    gap: var(--space-m);
    max-height: 100vh;
    overflow: hidden;
}

.trainingTask__header {
    display: grid ;
    grid-area: header;
    grid-template-columns: 300px 1fr 300px;
    width: 100%;
    align-items: center;
    justify-items: center;
    gap: var(--space-m);
}

.trainingPage__leftInfoContainer {
    grid-area: git;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.trainingPage__habrQR {
    grid-area: habr;
    display: flex;
    height: 100%;
    align-items: end;
}

.trainingTask__logoContainer {
    cursor: pointer;
    justify-self: start;
}

.trainingTask__bySberAI {
    z-index: 1;
    grid-column:1;
    height: auto;
    align-self: start;
}

.trainingTask__progressBarContainer {
    width: 100%;
    max-width: var(--max-theory-card-width);
    animation: showUp 0.5s ease-in;
}

.trainingTask__exitButtonContainer {
    grid-column: 3;
    justify-self: end;
}

.trainingTask__taskContainer {
    grid-area: task;
    max-width: var(--max-theory-card-width);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trainingTask__taskContainer__startContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
}

.trainingTask__taskContainer__startContainer__qr {
    display: flex;
    flex-direction: row;
    gap: var(--space-m);
    align-items: center;
    justify-content: center;
    animation: showUp 0.5s ease-in;
}

.trainingTask__buttonsContainer {
    grid-area: buttons;
    display: flex;
    flex-direction: row;
    max-width: 500px;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-m);
    z-index: var(--bottom-buttons-index);
}

.trainingTask__startCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-l);
    max-width: 800px;
    width: 100%;
    height: auto;
}

.trainingTask__startCardTitle {
    font-family: var(--main-font);
    font-size: var(--font-size-xl);
    text-align: center;
    line-height: var(--font-size-xl);
}

.trainingTask__startCardDescription {
    font-family: var(--main-font);
    font-size: var(--font-size-l);
    text-align: center;
    color: var(--disabled-button-color);
    font-weight: var(--font-weight-s);
}

.trainingTask__recognition {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: showDown 0.5s ease-in;
}

.trainingTask__taskContinueContainer {
    height: 100px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--bottom-modal-container-index);
}

.trainingTask__startAnimation {
    animation: showDown 0.5s ease-in;
}


.trainingTask__toHome {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-l);
    gap: var(--space-xl);
    background-color: var(--light-primary-color);
    animation: showFade 0.3s ease-in;
}

.trainingTask__result {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-s);
}


.trainingTask__result__image {
    width: 100%;
    height: calc(100% - 155px);
    object-fit: contain;
}

.trainingTask__resultTitle {
    color: var(--primary-color);
    font-size: var(--font-size-xl) !important;
}

.trainingTask__resultDescription {
    font-family: var(--main-font);
    font-size: var(--font-size-m);
    text-align: center;
}

.trainingTask__resultImage {
    max-height: 100%;
    max-width: 100%;
}

.trainingTask__resultCard {
    background-color: var(--accent-color);
    color: var(--accent-color);
}

.trainingPage__warningContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trainingPage__warning {
    position: absolute;
    left: 50%;
    bottom: var(--space-m);
    transform: translateX(-50%);
}

@media(max-width: 1300px) {
    .trainingTask{
        grid-template-areas:
        "header header header"
        "task task task"
        "sber buttons e";
        grid-template-rows: 90px calc(100vh - 190px - var(--page-padding-m) * 2 - var(--space-m) * 2) 100px;;
    }

    .trainingTask__header {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: dense;
    }

    .trainingTask__exitButtonContainer{
        grid-column: auto;
    }

    .trainingTask__progressBarContainer{
        grid-column: 1 / span 2;
        max-width: var(--max-theory-card-width);
    }

    .trainingTask__progressBarContainer {
        width: 100%;
    }
}
