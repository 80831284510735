@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.practiceSelectGif {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    animation: showDown 0.5s ease-in;
}

.practiceSelectGif__contentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.practiceSelectGif__titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--space-xs);
}

.practiceSelectGif__title {
    font-family: var(--main-font);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-m);
    text-align: center;
}

.practiceSelectGif__titleSignText {
   color: var(--primary-color);
}

.practiceSelectGif__gifsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, calc(100% - 30px - 15px - 50%));
    justify-items: right;
    gap: var(--space-s);
    height: 100%;
    margin-top: 10px;
}

.practiceSelectGif__gif {
    aspect-ratio: 4 / 3;
    height: 100%;
}

.practiceSelectGif__gif:nth-child(2n) {
    justify-self: left;
}

