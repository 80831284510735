@import "./theme/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--service-backround-color) !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;

}

::-webkit-scrollbar-track {
    /*background-color: #f5f5f5;*/
    border-radius: 10px;
    margin: 10px;
    /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
}

::-webkit-scrollbar-thumb {
    margin: 10px;
    border-radius: 10px;
    background-color: #4F4F53;
    border: 5px solid transparent;
}

p {
    padding: 0;
    margin: 0;
}
