@keyframes showFade {
    from { opacity: 0 }
    to { opacity: 1 }
}

.exitConfirmation__blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-blur);
    z-index: var(--exit-confirmation-blur-index);
}

.exitConfirmation__container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-l);
    gap: var(--space-xl);
    background-color: var(--on-surface-color);
    z-index: var(--exit-confirmation-container-index);
    animation: showFade 0.3s ease-in;
}

.exitConfirmation__contentContainer {
    max-width: 900px;
    width: 100%;
    gap: var(--space-m);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.exitConfirmation__description {
    font-family: var(--main-font);
    font-size: var(--font-size-m);
}

.exitConfirmation__buttons {
    display: flex;
    flex-direction: row;
    gap: var(--space-m)
}
