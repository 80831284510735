@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.practiceSelectWord {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    animation: showDown 0.5s ease-in;
}

.practiceSelectWord__contentContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--space-m);
    height: 100%;
    padding-top: var(--space-m);
}

.practiceSelectWord__title {
    font-family: var(--main-font);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-m);
    text-align: center;
}

.practiceSelectWord__buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
}
