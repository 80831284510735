@font-face {
    font-family: VerdanaPro;
    src: url('assets/fonts/VerdanaPro-Bold.ttf');
    font-weight: 600;
    font-display: fallback;
}

@font-face {
    font-family: VerdanaPro;
    src: url('assets/fonts/VerdanaPro-SemiBold.ttf');
    font-weight: 500;
    font-display: fallback;
}

@font-face {
    font-family: VerdanaPro;
    src: url('assets/fonts/VerdanaPro-Regular.ttf');
    font-weight: 400;
    font-display: fallback;
}

@font-face {
    font-family: VerdanaPro;
    src: url('assets/fonts/VerdanaPro-Light.ttf');
    font-weight: 300;
    font-display: fallback;
}
