@keyframes showFade {
    from { opacity: 0 }
    to { opacity: 1 }
}

@keyframes hideFade {
    from { opacity: 1 }
    to { opacity: 0 }
}

.webcamera {
    height: 100%;
    border-radius: 10px;
    z-index: var(--webcamera-index);
    margin: 0 auto;
    object-fit: cover;
    transform: scale(-1, 1);
}

.webcamera__container video {
    max-width: unset !important;
}
