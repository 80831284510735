@keyframes show {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.recognitionBlock {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-m);
}

.recognitionBlock__titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.recognitionBlock__title {
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-m);
    line-height: var(--font-size-m);
}

.recognitionBlock__gesture {
    line-height: var(--font-size-l);
}

.recognitionBlock__recognizedContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recognitionBlock__recognized {
    line-height: var(--font-size-m);
    font-size: var(--font-size-l);
}

.recognitionBlock__recognizedWords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 13px
}

.recognitionBlock__recognizedWord {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-m);
    line-height: var(--font-size-m);

    animation: show 0.4s ease-in;
}

.recognitionBlock__recognizedWord__loading {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-m);
    line-height: var(--font-size-m);
    color: var(--secondary-text-color);
}

.recognitionBlock__rightWord {
    color: var(--success-color)
}

.recognitionBlock__resultWord {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-m);
    line-height: var(--font-size-m);
    animation: show 0.4s ease-in;
}

.recognitionBlock__resultWord_right {
    color: var(--success-color);
}

.recognitionBlock__resultWord_someRight {
    color: var(--primary-color);
}

.recognitionBlock__resultWord_wrong {
    color: var(--danger-color);
}

.recognitionBlock__camera {
    width: 100%;
    height: calc( 100% - var(--space-m)*2 - 49px - 50px);
    position: relative;

    /*display: inline-block;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.recognitionBlock__cameraLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
