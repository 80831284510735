@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.notFoundPage {
    padding: var(--page-padding-m);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-xl);
    height: 100vh;
    animation: showDown 0.5s ease-in;
}

.notFoundPage__contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFoundPage__header {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-l);
    text-align: center;
}

.notFoundPage__description {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-m);
    text-align: center;
    margin-bottom: var(--space-m);
}
