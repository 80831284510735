@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.homePage {
    background: var(--service-backround-color);
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: var(--space-l);
    padding: var(--space-m) var(--space-xl);
    justify-content: center;
    align-items: center;
}

.homePage__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-m);
    width: 200px;
    height: 400px;
}

.homePage__links {
    max-width: 1080px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-l);
    max-height: 525px;
}

.homePage__buttonContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    padding: var(--space-l) !important; /* Переопределяю отступы компонента Card */
    height: 100%;
    width: 100%;
    max-width: 550px !important; /* Переопределяю размер компонента Card */
    max-height: 525px !important; /* Переопределяю размер компонента Card */
    box-sizing: border-box;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: 3px solid transparent;
    animation: showDown 1s ease-in;
}

.homePage__buttonContainer:hover {
    scale: 0.98;
    outline: 3px solid var(--primary-color);
}

.homePage__button__imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 90px);
}

.homePage__button__typographyContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
}

.homePage__button__title {
    margin: 0;
    padding: 0;
    line-height: 100%;
    text-align: center;
}

.homePage__button__description {
    margin: 0;
    padding: 0;
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-m);
    line-height: 1.4;
    text-align: center;
}

.homePage__logosContainer {
    max-width: 1080px;
    max-height: 100px;
    width: 100%;
    box-sizing: border-box;
}

.homePage__logos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1080px;
    max-height: 85px !important; /* Переопределяю размер компонента Card */
    width: 100%;
    box-sizing: border-box;
}

.homePage__testButton {
    position: absolute;
    top: 50px;
    right: 50px;
}

@media (max-height: 800px)  {
    .homePage__buttonContainer {
        max-width: 550px !important; /* Переопределяю размер компонента Card */
        max-height: 450px !important; /* Переопределяю размер компонента Card */
    }

    .homePage__button__imageContainer {
        display: flex;
        justify-content: center;
        max-height: 280px;
    }

    .homePage__logosContainer {
        max-width: 930px;
    }
}

