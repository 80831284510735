.qrcode {
    height: 200px;
    width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space-m);
}

.qrcode__image {
    height: calc(100% - 50px);
}

.qrcode__description {
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-m);
    text-align: center;
    white-space: pre-wrap;
}
