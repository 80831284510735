.widthContent {
    display: flex;
    justify-content: center;
    height: 100vh;
}


.widthContent__app {
    width: 100vw;
    height: 100vh;
}

